import logo from "./assets/polygenx_logo.svg";

function Main() {


    return (

        <div className={'black-bg w-screen h-screen'}>

            <div className={"w-full  md:w-2/3 max-w-[1024px] mx-auto"}>
                <img src={logo} alt="polygenx logo"/>
            </div>

                <div className="headline text-center text-white text-4xl md:text-6xl my-auto mb-4">
                    Coming Soon
                </div>

                <div className="text-center  text-xl text-white"><a href="mailto:contact@polygenx.com">contact@polygenx.com</a></div>
        </div>
    );
}

export default Main;
